import React, { useEffect, useState } from 'react'
import { css } from 'theme-ui'
import { Card, Text, Heading, Box } from 'theme-ui'

const NewsletterForm = ({
  ...props
}) => {
  
  const styles = {
    card: {
      position: `relative`
    },
    wrapper: {
      maxWidth: 500,
      textAlign: `center`,
      mx: `auto`,
      py: 3
    },
    icons: {
      display: [`none`, null, `block`],
      position: `absolute`,
      top: `-3rem`,
      left: `5rem`,
      svg: {
        display: `block`
      }
    },
    plane: {
      fontSize: `9rem`,
      color: `beta`
    },
    wind: {
      fontSize: `7rem`,
      color: `omegaLight`,
      transform: `rotate(120deg)`,
      mt: `0.5rem`,
      ml: `-3rem`
    },
    form: {
      maxWidth: 300,
      mx: `auto`,
      mt: 4
    }
  };


  useEffect(() => {

    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
    if (window.hbspt) {
        window.hbspt.forms.create({
        region: "na1",
        portalId: "7346852",
        formId: "f7a84cdc-9a8a-4b91-9bd7-f9f828442b0a",
        target: "#newsletterForm"
        });
    }

    });
    
  }, []);

  return (
    <Card variant='paper' sx={styles.card}>
      <Box sx={styles.wrapper}>
        <Heading variant='h2'>Subscribe To My Newsletter</Heading>
        <Text variant='p'>
          I'll only send worthwhile content I think you'll want, less than once a month, and promise to never spam or sell your information!
        </Text>
        <Box sx={styles.form}>
          <div id="newsletterForm" />
        </Box>
      </Box>
    </Card>
  );
}

export default NewsletterForm
